import type { GlobalComponents, InjectionKey } from 'vue'
import { type PanelReturn, panels } from './config'

export interface CommonPanelProperties {
  active?: boolean
  position?: 'right' | 'left'
  size?: 'sm' | 'md'
}

type ComponentProperties<T> = T extends keyof GlobalComponents
  ? GlobalComponents[T] extends abstract new (...args: any) => any
    ? InstanceType<GlobalComponents[T]>['$props']
    : never
  : never

type PanelNames = keyof typeof panels
type PanelComponentName<T extends PanelNames> = (typeof panels)[T]['component']

interface PanelContext<
  NAME extends PanelNames = any,
  DATA = NAME extends keyof PanelReturn ? PanelReturn[NAME] : any,
> {
  id: number
  name: NAME
  position: 'left' | 'right'
  size: 'sm' | 'md'
  component: PanelComponentName<NAME>
  props: Omit<ComponentProperties<PanelComponentName<NAME>>, keyof CommonPanelProperties>
  promise: Promise<DATA | undefined>
  resolve: (data?: DATA) => void
  reject: (reason?: any) => void
  onWillPop?: () => boolean
}
interface PopMeta<
  NAME extends PanelNames = any,
  // DATA = NAME extends keyof PanelReturn ? PanelReturn[NAME] : any,
> {
  id: NAME
  name: string
  position: 'left' | 'right'
  size: 'sm' | 'md'
  props: Omit<ComponentProperties<PanelComponentName<NAME>>, keyof CommonPanelProperties>
}
type PopCallback<
  NAME extends PanelNames = any,
  DATA = NAME extends keyof PanelReturn ? PanelReturn[NAME] : any,
> = (data: DATA | undefined, meta: PopMeta<NAME>) => (void | Promise<void>)
function noop() {}

type PanelsContext = ReturnType<typeof createPanelsStacks>
const panelsContextInjectionKey = Symbol(
  'panels-context',
) as InjectionKey<PanelsContext>

export function createPanelsStacks() {
  const { $posthog } = useNuxtApp()
  const stack = ref<PanelContext[]>([])
  const popEvents = new Map<string, PopCallback[]>()
  const canPop = computed(() => stack.value.length > 0)
  const id = ref(0)

  function onPop<
    NAME extends PanelNames = any,
    DATA = NAME extends keyof PanelReturn ? PanelReturn[NAME] : any,
  >(name: NAME, callback: PopCallback<NAME, DATA>): () => void
  function onPop<
    T = any,
  >(callback: PopCallback<any, T>): () => void
  function onPop(
    name?: any,
    callback?: any,
  ) {
    if (typeof name === 'function') {
      callback = name
      name = 'all'
    }

    const events = popEvents.get(name) || []

    if (!events.includes(callback)) {
      events.push(callback)
    }

    popEvents.set(name, events)

    function stop() {
      const events = popEvents.get(name) || []
      const index = events.indexOf(callback)
      if (index >= 0) {
        events.splice(index, 1)
      }

      if (events.length === 0) {
        popEvents.delete(name)
      }
      else {
        popEvents.set(name, events)
      }
    }

    if (getCurrentScope()) {
      onScopeDispose(stop)
    }

    return stop
  }

  function onWillPop(callback: () => boolean) {
    const last = stack.value.at(-1)
    if (!last) {
      return
    }

    last.onWillPop = callback
  }

  function willPop(): boolean {
    const last = stack.value.at(-1)
    if (!last) {
      return false
    }

    if (last.onWillPop) {
      return last.onWillPop()
    }

    return true
  }

  function push<
    NAME extends PanelNames = any,
    DATA = NAME extends keyof PanelReturn ? PanelReturn[NAME] : any,
  >(
    name: NAME,
    props: ComponentProperties<PanelComponentName<NAME>>,
  ): Promise<DATA | undefined> {
    const panel = panels[name]
    if (!panel) {
      return Promise.resolve(undefined)
    }

    let resolve: (data?: DATA) => void = noop
    let reject: (reason?: any) => void = noop

    const promise = new Promise<DATA | undefined>((_resolve, _reject) => {
      resolve = _resolve
      reject = _reject
    })

    const context: PanelContext<NAME, DATA> = {
      id: ++id.value,
      name,
      position: panel.position,
      size: panel.size,
      component: panel.component,
      props,
      promise,
      resolve,
      reject,
    }
    $posthog?.capture('panel_open', {
      panel_name: name,
    })
    stack.value.push(context)

    return context.promise
  }

  function pop<
    NAME extends PanelNames = any,
    DATA = NAME extends keyof PanelReturn ? PanelReturn[NAME] : any,
  >(data?: DATA) {
    if (!willPop()) {
      return false
    }

    const last = stack.value.pop() as PanelContext<NAME, DATA>
    if (!last) {
      return false
    }

    last.resolve(data)

    if (data) {
      $posthog?.capture('panel_save', {
        panel_name: last.name,
      })
    }
    else {
      $posthog?.capture('panel_discard', {
        panel_name: last.name,
      })
    }

    const namedEvents = popEvents.get(last.name) || []
    for (const event of namedEvents) {
      event(data, {
        id: last.id,
        name: last.name,
        position: last.position,
        size: last.size,
        props: last.props,
      })
    }
    const allEvents = popEvents.get('all') || []
    for (const event of allEvents) {
      event(data, {
        id: last.id,
        name: last.name,
        position: last.position,
        size: last.size,
        props: last.props,
      })
    }

    return true
  }

  function popAll() {
    while (pop()) {
      // nothing
    }
  }

  const panelsContext = {
    stack,
    canPop,

    onPop,
    onWillPop,
    push,
    pop,
    popAll,
  }
  provide(panelsContextInjectionKey, panelsContext)

  return panelsContext
}

export function usePanels() {
  let panelsContext = inject(panelsContextInjectionKey, null)
  if (!panelsContext) {
    panelsContext = createPanelsStacks()
  }

  return panelsContext
}
