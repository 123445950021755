import type {
  Customer,
  CustomerDevice,
  DeviceModel,
  Intervention,
  Item,
  Service,
  SparePart,
} from '@laast-io/types'

export interface LayoutPanel {
  id: string
  component: string
  position: 'right' | 'left'
  size: 'sm' | 'md'
}

/**
 * Key is the panel identifier set in the `panels` config below.
 * The type bound is the data payed by the panel when it's resolved.
 */
export interface PanelReturn {
  'customer-create': Customer
  'customer-edit': Customer
  'spare-part-create': SparePart
  'spare-part-edit': SparePart
  'device-model-create': DeviceModel
  'device-model-edit': DeviceModel
  'intervention-create': Intervention
  'service-create': Service
  'service-edit': Service
  'item-create': Item
  'item-edit': Item
  // links

  'link-customer-device-create': CustomerDevice
  'link-customer-device-edit': CustomerDevice
}

/**
 * Laast panels configuration.
 */
export const panels = {
  'account': {
    id: 'account',
    component: 'PanelAccount',
    position: 'left',
    size: 'sm',
  },
  'customer-create': {
    id: 'customer-create',
    component: 'PanelCustomerCreate',
    position: 'right',
    size: 'md',
  },
  'customer-edit': {
    id: 'customer-edit',
    component: 'PanelCustomerEdit',
    position: 'right',
    size: 'md',
  },
  'device-model-edit': {
    id: 'device-model-edit',
    component: 'PanelDeviceModelEdit',
    position: 'right',
    size: 'md',
  },
  'device-model-create': {
    id: 'device-model-create',
    component: 'PanelDeviceModelCreate',
    position: 'right',
    size: 'md',
  },
  'intervention-create': {
    id: 'intervention-create',
    component: 'PanelInterventionCreate',
    position: 'right',
    size: 'md',
  },
  'intervention-edit': {
    id: 'intervention-edit',
    component: 'PanelInterventionEdit',
    position: 'right',
    size: 'md',
  },
  'service-edit': {
    id: 'service-edit',
    component: 'PanelServiceEdit',
    position: 'right',
    size: 'md',
  },
  'service-create': {
    id: 'service-create',
    component: 'PanelServiceCreate',
    position: 'right',
    size: 'md',
  },
  'spare-part-edit': {
    id: 'spare-part-edit',
    component: 'PanelSparePartEdit',
    position: 'right',
    size: 'md',
  },
  'spare-part-create': {
    id: 'spare-part-create',
    component: 'PanelSparePartCreate',
    position: 'right',
    size: 'md',
  },
  'item-create': {
    id: 'item-create',
    component: 'PanelItemCreate',
    position: 'right',
    size: 'md',
  },
  'item-edit': {
    id: 'item-edit',
    component: 'PanelItemEdit',
    position: 'right',
    size: 'md',
  },
  // links
  'link-customer-device-create': {
    id: 'link-customer-device-create',
    component: 'PanelLinkCustomerDeviceCreate',
    position: 'right',
    size: 'md',
  },
  'link-customer-device-edit': {
    id: 'link-customer-device-edit',
    component: 'PanelLinkCustomerDeviceEdit',
    position: 'right',
    size: 'md',
  },
} as const satisfies Record<string, LayoutPanel>
